import { graphql } from 'gatsby'
import React from 'react'

import { IndexQueryQuery } from '../../../types/graphql-types'
import Meta from '../../components/meta/meta'
import Layout from '../../components/layout/layout'
import { Link } from 'gatsby'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const BlogIndex: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta

  return (
    <Layout edition="01-2021" location={location}>
      <Meta site={meta} />
      <div className="content-inner shadow-lg">
        <article className="row no-gutters text-center">
          <div className="col-12">
            <div
              className="bg-img h-100 w-100"
              style={{ backgroundImage: 'url(../../img/lon-edito-header.jpg)' }}
            ></div>
          </div>
          <div className="p-5 col-12">
            <h2>Editorial</h2>
            <Link
              to="/01-2021/editorial/"
              className="btn btn-outline-primary stretched-link"
            >
              Read More
            </Link>
          </div>
        </article>
        <article className="row no-gutters">
          <div className="col-md-6 bg-primary text-white p-5">
            <h2>New e-commerce and domains opening</h2>
            <p className="small">
              In March we will open a few new Longines domains to extend and
              reinforce our local presence in some countries such…
            </p>
            <Link
              to="/01-2021/new-domains-opening/"
              className="btn btn-outline-light stretched-link"
            >
              Read More
            </Link>
          </div>
          <div className="col-md-6">
            <div
              className="bg-img h-100 w-100"
              style={{
                backgroundImage: "url('../img/lon-new-domains-header.jpg'",
              }}
            ></div>
          </div>
        </article>
        <article className="row no-gutters">
          <div className="col-md-6 p-0 order-2 order-md-1">
            <div
              className="bg-img h-100 w-100"
              style={{
                backgroundImage: "url('../img/lon-new-header-menu.jpg'",
              }}
            ></div>
          </div>
          <div className="col-md-6 bg-primary-900 text-white p-5 order-1 order-md-2">
            <h2>New Header Menu</h2>
            <p className="small">
              You know, we are always evolving and improving our platforms to
              propose the best user experience within our website and for that
              we will be adding…{' '}
            </p>
            <Link
              to="/01-2021/new-header-menu/"
              className="btn btn-outline-light stretched-link"
            >
              Read More
            </Link>
          </div>
        </article>
        <article className="row no-gutters">
          <div className="col-md-6 bg-primary-800 text-white p-5">
            <h2>Click & Reserve</h2>
            <p className="small">
              In March we will open a few new Longines domains to extend and
              reinforce our local presence in some countries such…
            </p>
            <Link
              to="/01-2021/click-and-reserve/"
              className="btn btn-outline-light stretched-link"
            >
              Read More
            </Link>
          </div>
          <div className="col-md-6">
            <div
              className="bg-img h-100 w-100"
              style={{
                backgroundImage:
                  "url('../img/lon-click-and-reserve-header.jpg'",
              }}
            ></div>
          </div>
        </article>
        <article className="row no-gutters">
          <div className="col-md-6 p-0 order-2 order-md-1">
            <div
              className="bg-img h-100 w-100"
              style={{
                backgroundImage: "url('../img/lon-new-features-header.jpg'",
              }}
            ></div>
          </div>
          <div className="col-md-6 bg-primary-900 text-white p-5 order-1 order-md-2">
            <h2>Collection and product pages new features</h2>
            <p className="small">
              You know, we are always evolving and improving our platforms to
              propose the best user experience within our website and for that
              we will be adding…{' '}
            </p>
            <Link
              to="/01-2021/new-features/"
              className="btn btn-outline-light stretched-link"
            >
              Read More
            </Link>
          </div>
        </article>
        <article className="row no-gutters">
          <div className="col-md-6 bg-primary-800 text-white p-5">
            <h2>Online live Appointment</h2>
            <p className="small">
              Be like in your Longines store through an online video meeting
            </p>
            <Link
              to="/01-2021/online-live-appointment/"
              className="btn btn-outline-light stretched-link"
            >
              Read More
            </Link>
          </div>
          <div className="col-md-6">
            <div
              className="bg-img h-100 w-100"
              style={{
                backgroundImage:
                  "url('../img/lon-online-live-appointment-header.jpg'",
              }}
            ></div>
          </div>
        </article>
      </div>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query IndexQuery_01_2021 {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
      }
    }
  }
`
